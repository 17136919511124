// These values need to match the values being sent from the server
// (cf. `com.ssctech.algo.backstage.reports.ReportUtils` static members)
export enum GroupingLayerId {
  DATASET = -1,
  DETAIL = -2,
  CHARACTERISTIC = -3,
  LINKED = -4,
  CUSTOM_GROUPING = -5,
  GROUPING_LIST = -6,
  BUCKET_SORTING_CHAR_PLACEHOLDER = -7,
}
