import {
  convertDxTreeMapData,
  DxTreeMap,
  DxTreeMapDrilldownProps,
  TreeMapData,
  useUuid,
} from 'algo-react-dataviz';
import { useMemo } from 'react';
import { VisualizationComponent } from './visualizationComponents';

const DRILLDOWN_PROPS: DxTreeMapDrilldownProps = {
  enabled: true,
};

const DxTreeMapViz: VisualizationComponent = props => {
  const id = useUuid();

  const { reportRawData } = props;

  const data = useMemo<TreeMapData | null>(
    () => (!reportRawData ? null : convertDxTreeMapData(reportRawData)),
    [reportRawData],
  );

  return !data ? null : (
    <DxTreeMap
      {...{
        id,
        data,
      }}
      drilldownProps={DRILLDOWN_PROPS}
    />
  );
};

export default DxTreeMapViz;
