// @ts-strict-ignore
import { ReactiveDataGrid } from 'algo-react-dataviz';
import { FC } from 'react';
import { ReportProps } from '../shared/dataTypes';
import ActivePieChartViz from './ActivePieChartViz';
import BrushedBarChartViz from './BrushBarChartViz';
import ComposedChartViz from './ComposedChartViz';
import DxPieChartViz from './DxPieChartViz';
import createDxPointChartViz from './DxPointChartViz';
import createDxSeriesChartViz from './DxSeriesChartViz';
import DxTreeMapViz from './DxTreeMapViz';
import LegacyXYLineChartViz from './LegacyXYLineChartViz';
import LegacyXYStackedBarChartViz from './LegacyXYStackedBarChartViz';
import MixedBarChartViz from './MixedBarChartViz';
import RadialBarChartViz from './RadialBarChartViz';
import TwoLevelPieChartViz from './TwoLevelPieChartViz';
import XYAreaChartViz from './XYAreaChartViz';
import XYLineChartViz from './XYLineChartViz';

export type VisualizationComponent = FC<ReportProps>;
export const visualizationComponents: Record<string, VisualizationComponent> = {
  DX_TABLE: ReactiveDataGrid,
  // MAP_CHART: DxGeoChartViz, // Disabled for now (cf. SD-4019)
  BAR_CHART: createDxSeriesChartViz('bar'),
  STACKED_BAR_CHART: createDxSeriesChartViz('stackedBar'),
  MIXED_BAR_CHART: MixedBarChartViz,
  BRUSH_BAR_CHART: BrushedBarChartViz,
  STACKED_BRUSH_BAR_CHART: BrushedBarChartViz,
  LINE_GRAPH: createDxSeriesChartViz('spline'),
  XY_LINE_CHART: XYLineChartViz,
  PIE_CHART: DxPieChartViz,
  AREA_CHART: createDxSeriesChartViz('splineArea'),
  STACKED_AREA_CHART: createDxSeriesChartViz('stackedSplineArea'),
  XY_AREA_CHART: XYAreaChartViz,
  ACTIVE_PIE_CHART: ActivePieChartViz,
  TWO_LEVEL_PIE_CHART: TwoLevelPieChartViz,
  TREE_MAP: DxTreeMapViz,
  SCATTER_CHART: createDxPointChartViz('scatter'),
  BUBBLE_CHART: createDxPointChartViz('bubble'),
  COMPOSED_CHART: ComposedChartViz,
  RADIAL_BAR_CHART: RadialBarChartViz,
  // BULLET_GRAPH: BulletGraphViz,
  XY_BAR_CHART: LegacyXYStackedBarChartViz,
  XY_SPLINE_LINE_CHART: LegacyXYLineChartViz,
  XY_SPLINE_LINE_ONLY_CHART: LegacyXYLineChartViz,
  XY_LINE_NO_SYMBOL_CHART: LegacyXYLineChartViz,
  XY_SYMBOL_NO_LINE_CHART: LegacyXYLineChartViz,
  XY_STACKED_BAR_CHART: LegacyXYStackedBarChartViz,
};

export const legacyVisualizationComponents = {
  XY_LINE_CHART: LegacyXYLineChartViz,
  XY_SPLINE_LINE_CHART: LegacyXYLineChartViz,
  XY_SPLINE_LINE_ONLY_CHART: LegacyXYLineChartViz,
  XY_LINE_NO_SYMBOL_CHART: LegacyXYLineChartViz,
  XY_SYMBOL_NO_LINE_CHART: LegacyXYLineChartViz,
  XY_STACKED_BAR_CHART: LegacyXYStackedBarChartViz,
  XY_BAR_CHART: LegacyXYStackedBarChartViz,
};

export const getVisualizationComponent = (type: string, isLegacy = false) =>
  isLegacy && legacyVisualizationComponents.hasOwnProperty(type)
    ? legacyVisualizationComponents[type as keyof typeof legacyVisualizationComponents]
    : visualizationComponents[type];
