/*
  Also see:
    src\components\security\routing\AuthCfg.tsx
*/

//////////////////////////////////////////////////////////////////////
// PLEASE ... when making changes to this file:                     //
//  - update backstage/src/main/env/.env.ui                         //
//  - update backstage/src/main/jib/usr/local/algo/stadium/.env.ui  //
//////////////////////////////////////////////////////////////////////

export const toBool = (value: any, altValue?: any, defaultValue?: boolean) => {
  const lower = `${value}`.trim().toLowerCase();

  if (value === true || lower === 'true') {
    return true;
  } else if (value === false || lower === 'false') {
    return false;
  }

  if (altValue !== undefined) {
    const lowerAlt = `${altValue}`.trim().toLowerCase();
    if (altValue === true || lowerAlt === 'true') {
      return true;
    } else if (altValue === false || lowerAlt === 'false') {
      return false;
    }
  }

  return !!defaultValue;
};

export const getProp = (key: string): string => (window as any)?._env_?.[key] || process.env[key];

export const devUser = (window as any)._env_?.REACT_APP_DEV_USER || process.env.REACT_APP_DEV_USER;
export const devPass = (window as any)._env_?.REACT_APP_DEV_PASS || process.env.REACT_APP_DEV_PASS;

export const idleCountdownInSeconds: number =
  (window as any)._env_?.REACT_APP_IDLE_COUNTDOWN_SECONDS ||
  process.env.REACT_APP_IDLE_COUNTDOWN_SECONDS ||
  60;

export const idleTimeoutInMinutes: number =
  (window as any)._env_?.REACT_APP_IDLE_TIMEOUT_MINUTES ||
  process.env.REACT_APP_IDLE_TIMEOUT_MINUTES ||
  60;

export const REACT_APP_REFRESH_PERMISSIONS_SECONDS = 'REACT_APP_REFRESH_PERMISSIONS_SECONDS';

export const refreshPermissionsSeconds: number = Number(
  getProp(REACT_APP_REFRESH_PERMISSIONS_SECONDS) ?? 30,
);

export const isOidcClientEnabled =
  toBool((window as any)._env_?.REACT_APP_OIDC_CLIENT_ENABLED) ||
  toBool(process.env.REACT_APP_OIDC_CLIENT_ENABLED);

export const isPortalEnabled =
  toBool((window as any)._env_?.REACT_APP_PORTAL_ENABLED) ||
  toBool(process.env.REACT_APP_PORTAL_ENABLED);

const REACT_APP_PSBC_PORTAL_REFRESH_SECONDS_BEFORE_EXPIRATION =
  'REACT_APP_PSBC_PORTAL_REFRESH_SECONDS_BEFORE_EXPIRATION';
export const isPsbcPortalEnabled =
  toBool((window as any)._env_?.REACT_APP_PSBC_PORTAL_ENABLED) ||
  toBool(process.env.REACT_APP_PSBC_PORTAL_ENABLED);
export const psbcPortalRefreshSecondsBeforeExpire = Number(
  getProp(REACT_APP_PSBC_PORTAL_REFRESH_SECONDS_BEFORE_EXPIRATION) ?? 60,
);

export const REACT_APP_PSBC_PORTAL_LOGOUT_REDIRECT = 'REACT_APP_PSBC_PORTAL_LOGOUT_REDIRECT';
export const psbcLogoutRedirect: string = getProp(REACT_APP_PSBC_PORTAL_LOGOUT_REDIRECT);

export const WS_MAX_PENDING_REQUESTS =
  (window as any)._env_?.REACT_APP_WS_MAX_PENDING_REQUESTS ||
  process.env.REACT_APP_WS_MAX_PENDING_REQUESTS ||
  4;

export const devEnvironment =
  toBool((window as any)._env_?.REACT_APP_DEV_ENVIRONMENT) ||
  toBool(process.env.REACT_APP_DEV_ENVIRONMENT);

export const logLevel = process.env.REACT_APP_OIDC_CLIENT_LOG_LEVEL;

export const publicUrl = process.env.REACT_APP_PUBLIC_URL;

export const mode = (window as any)._env_?.NODE_ENV ?? process.env.NODE_ENV ?? '[unspecified]';

export const baseUrl = (window as any)._env_?.REACT_APP_REST_URL || process.env.REACT_APP_REST_URL;

export const wsBaseUrl = (window as any)._env_?.REACT_APP_WS_URL || process.env.REACT_APP_WS_URL;

export const rmuiBaseUrl =
  (window as any)._env_?.REACT_APP_RMUI_URL || process.env.REACT_APP_RMUI_URL;

export const autoRecover =
  toBool((window as any)._env_?.REACT_APP_AUTO_RECOVER) ||
  toBool(process.env.REACT_APP_AUTO_RECOVER);

export const includePathsInCSVHeaders =
  toBool((window as any)._env_?.REACT_APP_INCLUDE_PATHS_IN_CSV_HEADERS) ||
  toBool(process.env.REACT_APP_INCLUDE_PATHS_IN_CSV_HEADERS);

export const numBlocks = Number(
  (window as any)._env_?.REACT_APP_NUM_BLOCKS || process.env.REACT_APP_NUM_BLOCKS || 2,
);

export const isMergeReplaceEnabled = toBool(
  (window as any)._env_?.REACT_APP_MERGE_REPLACE_ENABLED,
  process.env.REACT_APP_MERGE_REPLACE_ENABLED,
  true,
);

export const isNativePasswordEncrypted = toBool(
  (window as any)._env_?.REACT_APP_NATIVE_PASSWORD_ENCRYPTED,
  process.env.REACT_APP_NATIVE_PASSWORD_ENCRYPTED,
  true,
);

export const subscribeReportTopic = '/topic/reports/';
export const subscribeReportNodesTopic = '/topic/nodes/';
export const publishTopic = '/app/reports/';
export const nodesTopic = '/app/nodes/';

export const subscribeRefreshNotificationTopic = '/topic/refreshNotification/';

export const subscribeCSVTopic = '/topic/csv/';
export const publishCSVTopic = '/app/csv/';

export const subscribePrintReportTopic = '/topic/printReport/';
export const publishPrintReportTopic = '/app/printReport/';

export const subscribeProxyTopic = '/topic/proxy/';
export const publishProxyTopic = '/app/proxy/';

export const subscribePendingUpdateTopic = '/topic/pendingUpdate/';

export const subscribeEditTopic = '/topic/edit/';
export const publishEditTopic = '/app/edit/';

export const subscribeProgressTopic = '/topic/progress/';
export const publishProgressTopic = '/app/progress/';

export const subscribeSortDescriptionTopic = '/topic/bucketSortDescription/';

export const CUSTOM_GROUPING_DEBUG = false;
