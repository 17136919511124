/**
 * DTO for `com.ssctech.algo.backstage.data.Settings` —
 * a report-specific settings object.
 */
export interface Settings {
  excludeFxRisk: boolean;
  excludeFxRiskOption: ExcludeFxRiskOption;
  reportInNativeCurrency: boolean;
  rebalanceToCash: boolean;
  manualGroupingOverride: boolean;
  showGrandTotalRow: boolean;
  showTotalColumn: boolean;
  showNumberOfBuckets: boolean;
  totalColumnsOnRight: boolean;
  numberOfHeaderChars: number;
  removeDuplicateNodes: boolean;
  removeEmptyBuckets: boolean;
  includeNaBucket: boolean;
  constrainedRows: number | '';
  drillThroughInheritance: boolean;
  reportingCurrency: boolean;
  scenarioSettings: boolean;
  applyFiltersToBenchmarksDrillThrough: boolean;
  liveReport: boolean;
  benchmarkSet: string;
  showFundConstituents: boolean;
  scaleBenchmarks: boolean;
  applyFiltersToBenchmarks: boolean;
  leafBucketsOnly: boolean;

  // FRTB-specific settings
  jurisdiction: string;
  defaultRiskChargeEquityMaturity: EquityMaturity;
  saFxCurvatureScaling: CurvatureScaling;
  includePositionsInImaSa: boolean;

  // SACCR-specific settings
  tradeAttributionCalculation: string;

  // CVA-specific settings
  eadType: string;

  // CCR-specific settings
  enabledAtLevel?: string;
  filterCharacteristic?: number;
  filterValue?: string;
}

export interface PrintOptions {
  title1?: string;
  title2?: string;
  columnWidthStrategy?:
    | 'CUSTOM'
    | 'FIT_TO_PAGE'
    | 'AUTOMATIC_FIT_HEADER_WRAP'
    | 'AUTOMATIC_FIT_NO_HEADER_WRAP';
  fontSize?: number;
  numHeaderColumns?: number;
  pageOrientationLandscape?: boolean;
  paperSize?: 'Legal' | 'Letter' | 'A4' | 'A3' | 'B4';
  showAllRows?: boolean;
  shadeGroupingRows?: boolean;
}

export type ExcludeFxRiskOption = 'entire' | 'portfolio' | 'benchmark';

export type EquityMaturity = '3 months' | '1 year';
export const createEquityMaturityOptions = (): EquityMaturity[] => ['3 months', '1 year'];

export type CurvatureScaling = 'None' | 'Subset' | 'All';
export const createCurvatureScalingOptions = (): CurvatureScaling[] => ['None', 'Subset', 'All'];

export const createDefaultSettings = (): Settings => ({
  excludeFxRisk: false,
  excludeFxRiskOption: 'entire',
  reportInNativeCurrency: false,
  rebalanceToCash: false,
  manualGroupingOverride: false,
  showGrandTotalRow: false,
  showTotalColumn: false,
  showNumberOfBuckets: false,
  totalColumnsOnRight: false,
  numberOfHeaderChars: 0,
  removeDuplicateNodes: false,
  removeEmptyBuckets: true,
  includeNaBucket: true,
  constrainedRows: 0,
  drillThroughInheritance: true,
  reportingCurrency: true,
  scenarioSettings: true,
  applyFiltersToBenchmarksDrillThrough: true,
  liveReport: true,
  benchmarkSet: 'Default',
  showFundConstituents: true,
  scaleBenchmarks: false,
  applyFiltersToBenchmarks: true,
  leafBucketsOnly: false,

  jurisdiction: 'Standard',
  defaultRiskChargeEquityMaturity: '3 months',
  saFxCurvatureScaling: 'None',
  includePositionsInImaSa: false,

  tradeAttributionCalculation: 'Euler',

  eadType: 'SA-CCR',
});
