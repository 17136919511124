// @ts-strict-ignore
import { Reducer } from '@reduxjs/toolkit';
import {
  CANCEL_CLOSE_DESIGNER,
  CLOSE_BUCKET_SORTING_MODAL,
  CLOSE_DATA_SETTINGS_MODAL,
  CLOSE_HEAT_MAP_MODAL,
  CLOSE_LEVEL_FORMAT_MODAL,
  CLOSE_MANAGE_CONDITIONS_MODAL,
  CLOSE_REPORT_STYLES_MODAL,
  CONFIRM_BUCKET_SORTING_MODAL,
  CONFIRM_CLOSE_DESIGNER,
  INTEND_CLOSE_DATA_SETTINGS,
  INTEND_CLOSE_DESIGNER,
  INTEND_CLOSE_GROUPING_LIST_EDITOR,
  OPEN_BUCKET_SORTING_MODAL,
  OPEN_HEAT_MAP_MODAL,
  OPEN_LEVEL_FORMAT_MODAL,
  OPEN_MANAGE_CONDITIONS_MODAL,
  OPEN_REPORT_STYLES_MODAL,
  SET_ACTIVE_PAGE,
  SET_CHANGE_WORKSPACE_MODAL_STATUS,
  SET_COPIED_STYLE_PROPS,
  SET_FOLIA_SYNTAX_GUIDE_OPEN,
  SET_IS_ABOUT_OPEN,
  SET_IS_REMOTE_CONSOLE_OPEN,
  SET_LEVEL_FORMAT_LEVEL_STYLES,
  SET_MANAGE_CONDITION_STYLES,
  SET_OVERWRITE_CONFIRMATION_MODAL_STATUS,
  SET_SORT_DESCRIPTION,
} from '../ActionTypes';
import {
  cancelCloseDesigner,
  closeBucketSortingModal,
  closeDataSettingsModal,
  closeHeatMapModal,
  closeLevelFormatModal,
  closeManageConditionsModal,
  closeReportStylesModal,
  confirmBucketSortingModal,
  confirmCloseDesigner,
  intendCloseDataSettings,
  intendCloseDesigner,
  intendCloseGroupingListEditor,
  openBucketSortingModal,
  openHeatMapModal,
  openLevelFormatModal,
  openManageConditionsModal,
  openReportStylesModal,
  setActivePage,
  setChangeWorkspaceModalStatus,
  setCopiedStyleProps,
  setFoliaSyntaxGuideOpen,
  setIsAboutOpen,
  setIsRemoteConsoleOpen,
  setLevelFormatLevelStyles,
  setManageConditionStyles,
  setOverwriteConfirmationModalStatus,
} from './actionCreators';
import { UiState, createDefaultUiState } from './state';
import { setSortDescription } from '../ReportActionCreators';

type Action =
  | ReturnType<typeof setActivePage>
  | ReturnType<typeof setChangeWorkspaceModalStatus>
  | ReturnType<typeof setOverwriteConfirmationModalStatus>
  | ReturnType<typeof intendCloseDesigner>
  | ReturnType<typeof confirmCloseDesigner>
  | ReturnType<typeof cancelCloseDesigner>
  | ReturnType<typeof setCopiedStyleProps>
  | ReturnType<typeof intendCloseDataSettings>
  | ReturnType<typeof intendCloseGroupingListEditor>
  | ReturnType<typeof closeDataSettingsModal>
  | ReturnType<typeof openReportStylesModal>
  | ReturnType<typeof closeReportStylesModal>
  | ReturnType<typeof setIsAboutOpen>
  | ReturnType<typeof closeManageConditionsModal>
  | ReturnType<typeof setManageConditionStyles>
  | ReturnType<typeof openManageConditionsModal>
  | ReturnType<typeof setIsRemoteConsoleOpen>
  | ReturnType<typeof openHeatMapModal>
  | ReturnType<typeof closeHeatMapModal>
  | ReturnType<typeof openLevelFormatModal>
  | ReturnType<typeof closeLevelFormatModal>
  | ReturnType<typeof setLevelFormatLevelStyles>
  | ReturnType<typeof setFoliaSyntaxGuideOpen>
  | ReturnType<typeof openBucketSortingModal>
  | ReturnType<typeof closeBucketSortingModal>
  | ReturnType<typeof confirmBucketSortingModal>
  | ReturnType<typeof setSortDescription>;

const reducer: Reducer<UiState, Action> = (state = createDefaultUiState(), action) => {
  switch (action.type) {
    case SET_ACTIVE_PAGE: {
      return {
        ...state,
        activePage: action.payload,
        previousPage: state.activePage,
      };
    }

    /**
     * The different ways this modal can be opened or closed correspond
     * to state changes in a different reducer slice. So, the difference
     * in behavior is handled by the corresponding thunks.
     */
    case SET_CHANGE_WORKSPACE_MODAL_STATUS: {
      return {
        ...state,
        changeWorkspaceModalStatus: action.payload,
      };
    }

    case SET_OVERWRITE_CONFIRMATION_MODAL_STATUS: {
      return {
        ...state,
        overwriteConfirmationModalStatus: action.payload,
      };
    }

    case INTEND_CLOSE_DESIGNER: {
      return {
        ...state,
        intendedPage: action.payload,
        closeDesignerModalOpen: true,
      };
    }

    case CONFIRM_CLOSE_DESIGNER: {
      return {
        ...state,
        activePage: state.intendedPage,
        intendedPage: null,
        closeDesignerModalOpen: false,
      };
    }

    case CANCEL_CLOSE_DESIGNER: {
      return {
        ...state,
        intendedPage: null,
        closeDesignerModalOpen: false,
      };
    }

    case INTEND_CLOSE_DATA_SETTINGS: {
      return {
        ...state,
        intendedPage: action.payload,
        closeDataSettingsModalOpen: true,
        closeGroupingListsEditorMode: 'navrail',
      };
    }

    case CLOSE_DATA_SETTINGS_MODAL: {
      return { ...state, closeDataSettingsModalOpen: false };
    }

    case INTEND_CLOSE_GROUPING_LIST_EDITOR: {
      return {
        ...state,
        closeDataSettingsModalOpen: true,
        closeGroupingListsEditorMode: 'metadata',
      };
    }

    case CLOSE_MANAGE_CONDITIONS_MODAL: {
      return {
        ...state,
        manageStylesModal: null,
      };
    }

    case OPEN_MANAGE_CONDITIONS_MODAL: {
      return {
        ...state,
        manageStylesModal: {
          sequenceId: action.payload.sequenceId,
          conditionalStyles: null,
        },
      };
    }

    case SET_MANAGE_CONDITION_STYLES: {
      return {
        ...state,
        manageStylesModal: {
          ...state.manageStylesModal,
          conditionalStyles: action.payload.styles,
        },
      };
    }

    case OPEN_REPORT_STYLES_MODAL: {
      return {
        ...state,
        uniformStylesModalState: action.payload,
      };
    }

    case CLOSE_REPORT_STYLES_MODAL: {
      return {
        ...state,
        uniformStylesModalState: null,
      };
    }

    case OPEN_HEAT_MAP_MODAL: {
      return {
        ...state,
        heatMapModalState: action.payload,
      };
    }

    case CLOSE_HEAT_MAP_MODAL: {
      return {
        ...state,
        heatMapModalState: null,
      };
    }

    case OPEN_LEVEL_FORMAT_MODAL: {
      return {
        ...state,
        levelFormatModal: {
          sequenceId: action.payload,
          levelStyleData: null,
        },
      };
    }

    case SET_LEVEL_FORMAT_LEVEL_STYLES: {
      return {
        ...state,
        levelFormatModal: {
          ...state.levelFormatModal,
          levelStyleData: action.payload,
        },
      };
    }

    case CLOSE_LEVEL_FORMAT_MODAL: {
      return {
        ...state,
        levelFormatModal: null,
      };
    }

    case SET_COPIED_STYLE_PROPS: {
      return {
        ...state,
        copiedStyleProps: action.payload,
      };
    }

    case SET_IS_ABOUT_OPEN: {
      return {
        ...state,
        isAboutOpen: action.payload,
      };
    }

    case SET_IS_REMOTE_CONSOLE_OPEN: {
      return {
        ...state,
        isRemoteConsoleOpen: action.payload,
      };
    }

    case SET_FOLIA_SYNTAX_GUIDE_OPEN: {
      return {
        ...state,
        isFoliaSyntaxGuideOpen: action.payload.open,
        foliaSyntaxGuideDialogMode: action.payload.dialogMode,
      };
    }

    case OPEN_BUCKET_SORTING_MODAL: {
      const { confirmation, sequenceId, rowUuid, columnUuids, bucketPath } = action.payload;
      return {
        ...state,
        bucketSortingModal: { confirmation, sequenceId, rowUuid, columnUuids, bucketPath },
      };
    }

    case CLOSE_BUCKET_SORTING_MODAL: {
      return { ...state, bucketSortingModal: null };
    }

    case CONFIRM_BUCKET_SORTING_MODAL: {
      return { ...state, bucketSortingModal: { ...state.bucketSortingModal, confirmation: false } };
    }

    case SET_SORT_DESCRIPTION: {
      return {
        ...state,
        sortDescriptions: {
          ...state.sortDescriptions,
          [action.payload.sequenceId]: {
            columnBucket: action.payload.columnBucket,
            rowBucketMap: action.payload.rowBucketMap,
            pending: action.payload.pending,
          },
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
