import { ValidReportRawData, decomposeCellId } from 'algo-react-dataviz';
import {
  getBucketMembersByPath,
  getBucketSortingOptions,
} from '../../components/bucket-sorting-modal/bucketSortingHelpers';
import { ContentPage } from '../../components/main/PageContent/contentPage';
import { oidcClientRefreshSecondsBeforeExpire } from '../../components/security/auth/authConfig';
import {
  isOidcClientEnabled,
  isPsbcPortalEnabled,
  psbcPortalRefreshSecondsBeforeExpire,
} from '../../components/shared/environment';
import { groupingOption } from '../../shared/constants';
import { extractWorkspacePayloadAttr } from '../../shared/utils';
import { addModifiers } from '../ActionCreators';
import { AppState } from '../configureStore';

export const getActivePage = (state: AppState) => state.ui.activePage;

export const isContentReady = (state: AppState) => state.user.loggedIn && state.user.hasUserInfo;

export const isHomeOpen = (state: AppState) => getActivePage(state) === ContentPage.HOME;
export const isWorkspaceOpen = (state: AppState) => getActivePage(state) === ContentPage.WORKSPACE;
export const isDesignerOpen = (state: AppState) => getActivePage(state) === ContentPage.DESIGNER;
export const isAdminOpen = (state: AppState) => getActivePage(state) === ContentPage.ADMIN;

export const isDataSettingsOpen = (state: AppState) =>
  getActivePage(state) === ContentPage.DATA_SETTINGS;

export const isFoliaSyntaxGuidePopoverOpen = (state: AppState) =>
  (state.ui.isFoliaSyntaxGuideOpen && !state.ui.foliaSyntaxGuideDialogMode) ?? false;

export const isFoliaSyntaxGuideDialogOpen = (state: AppState) =>
  (state.ui.isFoliaSyntaxGuideOpen && state.ui.foliaSyntaxGuideDialogMode) ?? false;

export const getUniformStylesModalSequenceId = (state: AppState) =>
  state.ui.uniformStylesModalState?.sequenceId ?? null;
export const getUniformStylesModalTargetType = (state: AppState) =>
  state.ui.uniformStylesModalState?.targetType ?? null;
export const getUniformStylesModalTargets = (state: AppState) =>
  state.ui.uniformStylesModalState?.targets ?? [];
export const getUniformStylesEditorType = (state: AppState) =>
  state.ui.uniformStylesModalState?.editorType ?? null;
export const getUniformStylesStyleId = (state: AppState) =>
  state.ui.uniformStylesModalState?.styleId ?? null;
export const getUniformStylesLevelStyleProps = (state: AppState) => {
  const levelToEdit = getUniformStylesLevelToEdit(state);
  return (
    ((levelToEdit || levelToEdit === 0) &&
      state.ui?.levelFormatModal?.levelStyleData?.[levelToEdit]?.[0]) ??
    null
  );
};
export const getUniformStylesLevelToEdit = (state: AppState) =>
  state.ui?.uniformStylesModalState?.levelToEdit ?? null;

export const getManageConditionsSequenceId = (state: AppState) =>
  state.ui.manageStylesModal?.sequenceId ?? null;
export const getManageConditionsStyles = (state: AppState) =>
  state.ui.manageStylesModal?.conditionalStyles ?? null;

export const getHeatMapModalSequenceId = (state: AppState) =>
  state.ui.heatMapModalState?.sequenceId ?? null;
export const getHeatMapModalTargetType = (state: AppState) =>
  state.ui.heatMapModalState?.targetType ?? null;
export const getHeatMapModalTargets = (state: AppState) =>
  state.ui.heatMapModalState?.targets ?? [];

export const getLevelFormatModalSequenceId = (state: AppState) =>
  state.ui.levelFormatModal?.sequenceId ?? null;
export const getLevelFormatModalLevelStyleData = (state: AppState) =>
  state.ui.levelFormatModal?.levelStyleData ?? null;

export const getCopiedStyleProps = (state: AppState) => state.ui.copiedStyleProps;

export const isInTokenRefreshWindow = (state: AppState) => {
  if (isOidcClientEnabled || isPsbcPortalEnabled) {
    // this can be null if the token is being/scheduled to be refreshed or if it is just before it expires
    const expIn =
      state.user?.expiresIn?.() ??
      (state.user?.expiresAtMs ? (state.user?.expiresAtMs - Date.now()) / 1000 : null);

    const before = isOidcClientEnabled
      ? oidcClientRefreshSecondsBeforeExpire
      : psbcPortalRefreshSecondsBeforeExpire;

    if (!expIn || expIn <= before) {
      // don't re-request if within the token refresh window
      return true;
    }
  }
  return false;
};

export const getOptions = (state: AppState) => {
  //@ts-ignore due to "Property 'reportData' does not exist on type 'never'."
  const headers = (state.report.reportData[state.ui.bucketSortingModal?.sequenceId]
    ?.raw as ValidReportRawData)?.headers;

  return state.ui.bucketSortingModal?.bucketPath
    ? getBucketMembersByPath(state)
    : [
        { ...groupingOption, columnId: headers?.children.find(h => h.id)?.id },
        ...getBucketSortingOptions(state),
      ];
};

export const getBucketSortClickedChar = (state: AppState) => {
  const { bucketSortingModal } = state.ui;
  if (!bucketSortingModal) return null;

  const sequenceId = bucketSortingModal.sequenceId;

  const selectedColumn = decomposeCellId(
    extractWorkspacePayloadAttr<string[]>('selectedElements', sequenceId, state)?.[0] ?? '',
  ).columnId;

  const availableCharacteristics = addModifiers(state.groupingLists.availableCharacteristics).map(
    c => ({
      ...c,
      columnId: getOptions(state).find(o => o.charId === c.charId && o.modifier === c.modifier)
        ?.columnId,
    }),
  );

  return availableCharacteristics.find(c => c.columnId === selectedColumn);
};
