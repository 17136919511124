import { ContentPage } from '../../components/main/PageContent/contentPage';
import { StyleProps } from '../../model/styles/styleProps';
import { StyleRule } from '../../model/styles/styleRule';
import { StyleTarget, StyleTargetType } from '../../model/styles/styleTarget';
import { LevelStyleData } from '../../shared/dataTypes';
import { StyleEditorMode } from './actionCreators';
import { ChangeWorkspaceModalStatus } from './changeWorkspaceModalStatus';
import { OverwriteConfirmationStatus } from './overwriteConfirmationStatus';

export interface UiState {
  activePage: ContentPage;
  intendedPage: ContentPage | null;
  previousPage: ContentPage | null;

  overwriteConfirmationModalStatus: OverwriteConfirmationStatus | null;
  changeWorkspaceModalStatus: ChangeWorkspaceModalStatus | null;
  closeDesignerModalOpen: boolean;
  closeDataSettingsModalOpen: boolean;
  closeGroupingListsEditorMode: 'metadata' | 'navrail' | null;

  uniformStylesModalState: {
    sequenceId: number;
    targetType: StyleTargetType;
    targets: StyleTarget[];
    editorType: StyleEditorMode;
    styleId: string;
    levelToEdit: number;
  } | null;

  heatMapModalState: {
    sequenceId: number;
    targetType: StyleTargetType;
    targets: StyleTarget[];
  } | null;

  manageStylesModal: {
    sequenceId: number | null;
    conditionalStyles: StyleRule[] | null;
  } | null;

  levelFormatModal: {
    sequenceId: number | null;
    levelStyleData: LevelStyleData | null;
  } | null;

  bucketSortingModal: {
    confirmation: boolean; // true: show BucketSortingConfirmation. false: show BucketSortingModal.
    sequenceId: number;
    columnUuids: string[];
    rowUuid: string;
    bucketPath?: string[];
  } | null;

  copiedStyleProps: StyleProps | null;

  isAboutOpen: boolean;
  isRemoteConsoleOpen: boolean;
  pendingCsvExports: string[];
  isFoliaSyntaxGuideOpen: boolean;
  foliaSyntaxGuideDialogMode?: boolean;
  sortDescriptions: { [sequenceId: number]: SortDescription };
}

export interface SortDescription {
  columnBucket: string;
  rowBucketMap: { [id: string]: string };
  pending: boolean;
}

export interface SortDescriptionMessage extends SortDescription {
  sequenceId: number;
}

export const DEFAULT_CONTENT_PAGE = ContentPage.HOME;
export const createDefaultUiState = (): UiState => ({
  activePage: DEFAULT_CONTENT_PAGE,
  intendedPage: null,
  previousPage: null,

  overwriteConfirmationModalStatus: null,
  changeWorkspaceModalStatus: null,
  closeDesignerModalOpen: false,
  closeDataSettingsModalOpen: false,
  closeGroupingListsEditorMode: null,

  uniformStylesModalState: null,
  manageStylesModal: null,
  copiedStyleProps: null,
  heatMapModalState: null,
  levelFormatModal: null,
  bucketSortingModal: null,

  isAboutOpen: false,
  isRemoteConsoleOpen: false,
  pendingCsvExports: [],
  isFoliaSyntaxGuideOpen: false,
  sortDescriptions: {},
});
