import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { FC, useEffect } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { Provider as ReduxProvider } from 'react-redux';
import SecuritySelector from './components/security/SecuritySelector';
import MemoizedMonacoProvider from './lang/memoized-monaco/MemoizedMonacoProvider';
import LanguageServiceProvider from './lang/services/LanguageServiceProvider';
import { createStore } from './redux/configureStore';
import { theme } from './theme';

const store = createStore();

const App: FC = () => {
  useEffect(() => {
    console.log(
      '%cWelcome to \n%cSSC %cStadium %cSuperNova',
      'font-size:36px;color:#000;background-color:#f7f6e8',
      'font-size:36px;color:#273a96;background-color:#f7f6e8',
      'font-size:36px;color:#5aff5a;background-color:#f7f6e8',
      'font-size:36px;color:#f36c44;background-color:#f7f6e8',
    );
  }, []);

  return (
    <ReduxProvider {...{ store }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider {...{ theme }}>
          <SnackbarProvider dense preventDuplicate>
            <MemoizedMonacoProvider>
              <LanguageServiceProvider>
                <CssBaseline />
                <SecuritySelector />
              </LanguageServiceProvider>
            </MemoizedMonacoProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ReduxProvider>
  );
};

export default App;
