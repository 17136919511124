// @ts-strict-ignore
import { Modifier, SortConfig, SortDirection, XROOT, YROOT } from 'algo-react-dataviz';

// TODO: Export somewhere else
const COL_NAME_CHAR_ID = 1000;

export const createDefaultSort = (): SortConfig => ({
  columnIds: [XROOT],
  bucketPath: [],
  rowBuckets: {
    [YROOT]: {
      cascadeSorting: true,
      showAllChars: false,
      sortElements: [{ charId: COL_NAME_CHAR_ID, modifier: Modifier.PORT, direction: 'asc' }],
    },
  },
});

export const getNewSort = (sort: SortConfig | null, charId: number): SortConfig => {
  if (sort === null) {
    return createDefaultSort();
  }

  const sortElement = sort.rowBuckets[YROOT].sortElements[0];

  return {
    columnIds: [XROOT],
    bucketPath: [],
    rowBuckets: {
      [YROOT]: {
        cascadeSorting: true,
        showAllChars: false,
        sortElements: [
          {
            charId,
            modifier: Modifier.PORT,
            direction:
              charId === sortElement.charId
                ? getReverseSortDirection(sortElement.direction)
                : 'asc',
          },
        ],
      },
    },
  };
};

export const getReverseSortDirection = (direction: SortDirection): SortDirection => {
  switch (direction) {
    case 'asc':
      return 'desc';

    case 'desc':
      return 'asc';
  }
};
