// @ts-strict-ignore
import { TableBandHeader } from '@devexpress/dx-react-grid';
import {
  MappableNode,
  Modifier,
  NodeType,
  RowField,
  SortConfig,
  SortElement,
  ValidReportRawData,
  YROOT,
  extractColHeaders,
  mapData,
} from 'algo-react-dataviz';
import { AppState } from '../../redux/configureStore';
import { ReportDefinition } from '../../shared/dataTypes';
import { GroupingLayerId } from '../designer-panel/drag-and-drop/groupingLayerId';
import { BucketSortingOption } from './reducer';

interface PathItem {
  title: string;
  char: boolean;
}

export const getBucketSortingOptions = (state: AppState): BucketSortingOption[] => {
  const { sequenceId, columnUuids } = state.ui.bucketSortingModal ?? {};
  return sequenceId && columnUuids
    ? getBucketMembersByColumn(state, sequenceId, columnUuids[0])
    : [];
};

export const getBucketMembersByColumn = (
  state: AppState,
  sequenceId: number,
  columnUuid: string,
): BucketSortingOption[] => {
  const convertedHeaders = getConvertedHeaders(state)(sequenceId);
  const reportDefChars = state.report.reportDefinition[sequenceId].chars;

  if (!reportHasHorizontalGrouping(state)(sequenceId)) {
    return convertedHeaders.map((h, i) => ({
      columnId: h.columnName,
      label: h.title,
      charId: reportDefChars[i]?.charId,
      modifier: reportDefChars[i]?.modifier,
    }));
  }

  if (convertedHeaders.find(h => h.columnName === columnUuid)?.title === 'Grouping') {
    return state.report.reportDefinition[sequenceId].chars.map(c => ({
      columnId: columnUuid,
      label: '',
      charId: c.charId,
      modifier: c.modifier,
    }));
  }

  if (isHeaderChar(state)(sequenceId, columnUuid)) {
    return state.report.reportDefinition[sequenceId].chars.map(c => {
      const columnId = (state.report.reportData[sequenceId]
        .raw as ValidReportRawData).headers.children.find(
        h => h.props.columnKeyValue === c.charId && h.props.modifier === c.modifier,
      )?.id;

      return {
        // The clicked cell and all other header characteristics should be returned with
        // their column IDs. Other chars are returned with null columnIds, because they are
        // not in the report in the same way - as totals on the horizontal.
        columnId: isHeaderChar(state)(sequenceId, columnId) ? columnId : null,
        label: '',
        charId: c.charId,
        modifier: c.modifier,
      };
    });
  }

  const horizontalChar = getHorizCharPos(state)(sequenceId);
  const rawPath = getHorizGroupingPath(convertedHeaders, columnUuid, []);

  if (rawPath) {
    const path: PathItem[] = rawPath.map((title, i) => ({
      title,
      char:
        (horizontalChar === 'first' && i === 0) ||
        (horizontalChar === 'last' && i === rawPath.length - 1),
    }));

    return [
      // Header chars, if any
      ...reportDefChars
        .slice(0, state.report.reportDefinition[sequenceId].settings?.numberOfHeaderChars ?? 0)
        .map(c => ({
          label: null,
          columnId: (state.report.reportData[sequenceId]
            .raw as ValidReportRawData).headers.children.find(
            h => h.props.columnKeyValue === c.charId,
          )?.id,
        })),

      // All other chars
      ...getBucketMembers(convertedHeaders, path, null),
    ].map((m, i) => ({
      ...m,
      charId: reportDefChars[i]?.charId,
      modifier: reportDefChars[i]?.modifier,
    }));
  } else return [];
};

const isHeaderChar = (state: AppState) => (sequenceId: number, columnId: string) =>
  (state.report.reportData[sequenceId].raw as ValidReportRawData).headers.children
    .filter(h => h.props?.columnKeyValue !== 6)
    .slice(0, state.report.reportDefinition[sequenceId].settings?.numberOfHeaderChars ?? 0)
    .some(h => h.id === columnId);

const getConvertedHeaders = (state: AppState) => (sequenceId: number) => {
  const raw = state.report.reportData[sequenceId].raw as ValidReportRawData;

  return extractColHeaders(
    raw.headers,
    raw.data?.children[0].payload.map(value => `${value[RowField.COL_HASH]}`),
  );
};

const getHorizCharPos = (state: AppState) => (sequenceId: number) =>
  state.report.reportDefinition[sequenceId].horizontalChars[0].layerId ===
  GroupingLayerId.CHARACTERISTIC
    ? 'first'
    : 'last';

export const getBucketPath = (state: AppState) => (sequenceId: number, columnId: string) => {
  const horizontalChar = getHorizCharPos(state)(sequenceId);
  const rawPath = getHorizGroupingPath(getConvertedHeaders(state)(sequenceId), columnId, []);

  return (
    rawPath?.filter(
      (_title, i) =>
        (horizontalChar === 'first' && i !== 0) ||
        (horizontalChar === 'last' && i !== rawPath.length - 1),
    ) ?? []
  );
};

// In the case where there's a Grouping column and other banded columns due to a horizontal
// grouping, we want only the banded columns and not the Grouping column. This function calculates
// the depth for each and returns only those with the max depth.
const getBandsWithMaxDepth = (bands: TableBandHeader.ColumnBands[]) => {
  const maxDepth = bands.reduce((acc, cur) => Math.max(getBandDepth(cur), acc), 0);
  return bands.filter(b => getBandDepth(b) === maxDepth);
};

const getBandDepth = (band: TableBandHeader.ColumnBands) =>
  band.children ? 1 + getBandDepth(band.children[0]) : 0;

const getHorizGroupingPath = (
  bands: TableBandHeader.ColumnBands[],
  columnId: string,
  path: string[],
): string[] | null => {
  const nonGroupingBands = getBandsWithMaxDepth(bands);

  if (!nonGroupingBands[0].children) {
    // leaf node
    const matchedLeaf = bands.find(b => b.columnName === columnId);
    return matchedLeaf ? [...path, matchedLeaf.title ?? ''] : null;
  } else {
    // middle node
    return nonGroupingBands
      .flatMap(b => getHorizGroupingPath(b.children, columnId, [...path, b.title]))
      .filter(b => b);
  }
};

export const getBucketMembersByPath = (state: AppState): BucketSortingOption[] => {
  const { sequenceId, bucketPath } = state.ui.bucketSortingModal;
  const horizCharPos = getHorizCharPos(state)(sequenceId);
  const pathMapped = bucketPath.map(p => ({ title: p, char: false }));
  const reportDefChars = state.report.reportDefinition[sequenceId].chars;

  return getBucketMembers(
    getConvertedHeaders(state)(sequenceId),
    horizCharPos === 'first'
      ? [{ title: '', char: true }, ...pathMapped]
      : [...pathMapped, { title: '', char: true }],
    null,
  ).map((m, i) => ({
    ...m,
    charId: reportDefChars[i]?.charId,
    modifier: reportDefChars[i]?.modifier,
  }));
};

const getBucketMembers = (
  bands: TableBandHeader.ColumnBands[],
  path: PathItem[],
  charLabel: string | null,
): { columnId: string | undefined; label: string | null }[] => {
  const nonGroupingBands = getBandsWithMaxDepth(bands);

  return nonGroupingBands
    .filter(b => pathMatch(path[0], b.title))
    .flatMap(b => {
      const newCharLabel = charLabel === null && path[0]?.char && b.title ? b.title : charLabel;

      return b.children
        ? getBucketMembers(b.children, path.slice(1), newCharLabel)
        : { columnId: b.columnName, label: newCharLabel };
    });
};

const pathMatch = (path: PathItem, title?: string) => !path || path.char || path.title === title;

export const reportHasHorizontalGrouping = (state: AppState) => (sequenceId: number) =>
  state.report.reportDefinition[sequenceId].horizontalChars.some(
    c => c.layerId !== GroupingLayerId.CHARACTERISTIC,
  );

export const reportContainsChar = (def: ReportDefinition, charId: number, modifier: Modifier) =>
  def.chars.some(c => c.charId === charId && c.modifier === modifier);

export const charIsInSort = (sortElements: SortElement[], charId: number, modifier: Modifier) =>
  sortElements.some(s => s.charId === charId && s.modifier === modifier);

export const noneOption = {
  label: 'None',
  charId: GroupingLayerId.BUCKET_SORTING_CHAR_PLACEHOLDER,
  modifier: Modifier.PORT,
  columnId: undefined,
};

export const isSortOperable = (state: AppState) => (sequenceId: number) => {
  const sort = state.report.reportDefinition[sequenceId]?.sort;
  if (!sort) return false;

  const path = getBucketPath(state)(
    sequenceId,
    getCurrentColumnId(state)(sequenceId, sort.columnIds),
  );

  return (
    path.every(p => sort.bucketPath.includes(p)) && sort.bucketPath.every(p => path.includes(p))
  );
};

export const getCurrentColumnId = (state: AppState) => (sequenceId: number, columnIds: string[]) =>
  mapData(
    (state.report.reportData[sequenceId]?.raw as ValidReportRawData).headers as MappableNode,
    0,
  )
    .map(h => h.id)
    .find(id => columnIds.includes(id));

export const isTotalRow = (reportRawData: ValidReportRawData, rowId: string) =>
  reportRawData.data.children[0].type === NodeType.TOTAL &&
  reportRawData.data.children[0].payload[0][RowField.ROW_HASH] === rowId;

export const hasBucketSort = (
  sort: SortConfig,
  rawData: ValidReportRawData,
  rowUuid: string,
  columnUuid: string,
) =>
  sort &&
  Object.entries(sort.rowBuckets)
    .find(r => r[0] === rowUuid || (r[0] === YROOT && isTotalRow(rawData, rowUuid)))?.[1]
    .sortElements.some(e => e.columnId === columnUuid);
